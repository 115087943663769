import {IAPIResponse, IBrandConfig, IOptionType, TQuestionType} from 'spekit-types';

export const IS_IMPERSONATING = 'is-impersonating';

// Build version should be equivalent to release version.
export const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION || '0.0';

export const CHROME_EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/spekit/nbphjjmibhopfgkkbpoggemjnihnchhk';

export const HELP_CENTER_URL =
  'https://help.spekit.com/hc/en-us/articles/9898457037211-Knowledge-Check-Overview-Setup';

export const FLOWS_OVERVIEW_URL =
  'https://help.spekit.com/hc/en-us/articles/9922317367579-Flows-Overview-Setup';

export const FLOWS_HELP_CENTER_URL =
  'https://help.spekit.com/hc/en-us/articles/9922473674267#h_01GWPTK91YR1N3KM2FY8WM505E';

export const FLOWS_FLOATING_STEP_HELP_URL =
  'https://help.spekit.com/hc/en-us/articles/9922348805275-Flows-Troubleshooting-FAQs#h_01GGDVCA23ZC29SV3ET4DK7V85';

export const FLOWS_COMPATIBLE_HELP_URL =
  'https://help.spekit.com/hc/en-us/articles/9922348805275-Flows-Troubleshooting-FAQs#h_01GGDVBVKEPAZW728HD7YZJJBK';

export const FAKE_RESPONSE: IAPIResponse = {
  created_by: '',
  updated_by: '',
  created_on: '',
  updated_on: '',
  deleted: false,
};

export const QUESTION_TYPE_OPTIONS: Array<IOptionType<TQuestionType, string>> = [
  {value: 'radio', label: 'Single select'},
  {value: 'multiple-choices', label: 'Multiple select'},
];

export const IGNORED_SENTRY_ERRORS = [
  `Extension context invalidated.`,
  `Could not establish connection. Receiving end does not exist.`,
  `Cannot read properties of undefined (reading 'sendMessage')`,
  `The message port closed before a response was received.`,
  `Cannot read properties of undefined (reading 'local')`,
  `Could not inject`,
  `NOLOGIN`,
  `Failed to fetch`,
  `Your company account has been blocked! Please contact support@spekit.co`,
  `Failed to construct 'Notification': Illegal constructor.`,
  `LOCKEDOUT`,
  `this.props.onClose is not a function`,
  `A listener indicated an asynchronous response by returning true, but the message channel closed before a response was received`,
  `ResizeObserver loop limit exceeded`,
  `ResizeObserver loop completed with undelivered notifications.`,
  `apply`,

  /* Skip logging missing frames/document related errors during the
   * navigation cycle to sentry. This is a harmless race condition which
   * happens over the normal page lifecycle as other async JS and user
   * actions cause page layout to change.  It shouldn't impact any
   * functionality.
   */
  `No document with id`,
  `No frame with id`,
];

export const PUBLIC_ROUTES = [
  '/kb/signup',
  '/login',
  '/sso-login',
  '/licensesExceeded',
  '/retrieve-user',
  '/verifypin',
  '/passwordreset',
  '/reset-password',
  '/reset-password-error',
  '/loginSF',
  '/acceptinvite',
  '/notfound',
  '/logout',
  '/notallowed',
  '/app/public',
  '/app/share/content',
  '/app/share/deal-room',
  '/appscompat-status',
  '/appscompat-add',
];

export const MAX_ZINDEX = 2147483647;
export const ASSET_FILE_SIZE_LIMIT = 20972544;

export const SW_USER_DATA_MSG_TYPE = 'SW_USER_DATA_MSG_TYPE'; // service worker message type
export const SW_STOP_ABLY_MSG_TYPE = 'SW_STOP_ABLY_MSG_TYPE'; // service worker message type
export const SW_START_REFRESH_INTERVAL_MSG_TYPE = 'SW_START_REFRESH_INTERVAL_MSG_TYPE'; // service worker message type
export const SW_STOP_REFRESH_INTERVAL_MSG_TYPE = 'SW_STOP_REFRESH_INTERVAL_MSG_TYPE'; // service worker message type
export const ABLY_COMPANY_MSG_TYPE = 'ABLY_COMPANY_MSG_TYPE';
export const ABLY_USER_MSG_TYPE = 'ABLY_USER_MSG_TYPE';

export const contentTypeMapper = {
  spek: 'Spek',
  topic: 'Topic',
  flow: 'Flow',
  spotlight: 'Spotlight',
  quiz: 'Knowledge Check',
  asset: 'PDF',
  seismic: 'Seismic',
};

export const spekPermissions = {
  shareable: [
    'businessterms.share_own',
    'salesforcefields.share_own',
    'salesforcefieldvalues.share_own',
    'salesforceobjects.share_own',
  ],
  hideable: [
    'businessterms.hide_own',
    'salesforcefields.hide_own',
    'salesforcefieldvalues.hide_own',
    'salesforceobjects.hide_own',
  ],
};

export const EXISTING_RESOURCE = 'A resource with the given parameters already exists.';
export const PERMISSION_DENIED = 'You do not have permission to perform this action.';
export const PASSWORD_MISMATCH =
  "The password you provided doesn't match our records. Please try again.";
export const CUSTOM_FIELD_DELETE_MESSAGE = `Are you sure? This custom field will no longer be visible, and all related data will be lost.
  Please do not close this window or navigate away while waiting for this action to complete.`;

export const CONTENT_SHARING = {
  ALL_USERS: 'Allow all users to share this content externally',
  ALLOW_DOWNLOAD: 'Allow all external users to download this content',
  SYSTEM_SHARING: 'Allow users to share content externally',
};

export const CKE_CONTENT_SIZE_ERROR =
  'Spek size is too large. If you copy and pasted images, try uploading them instead to reduce the content size.';

export const ERROR_MESSAGE_TEST_ID = 'error-message';

export const ROLES = {
  SUPER_ADMIN: 'Super Admin',
  ACCOUNT_ADMIN: 'Account Admin',
  TEAM_ADMIN: 'Team Admin',
  EXPERT: 'Expert',
  VIEWER: 'Viewer',
} as const;

export const SPEKIT_MARK_NODE_NAME = 'SPEKITMARK';

export const FALLBACK_BRAND_CONFIG: IBrandConfig = {
  images: {
    brandLogoUrl: null,
    iconLogoUrl: null,
    whiteBrandLogoUrl: null,
    whiteIconLogoUrl: null,
  },
  colors: {
    primary: null,
  },
};

export const BRAND_LOGO_DIMENSIONS = {
  maxWidth: '130px',
  maxHeight: '40px',
} as const;

export const ICON_LOGO_DIMENSIONS = {
  maxWidth: '30px',
  maxHeight: '30px',
} as const;

export const CANNY_REDIRECT =
  'https://spekit.canny.io/admin/settings/sso-redirect?works=true';

export const CANNY_COMPANY_ID = '6109c7c2ee097a157f2b51b8';

export const CONTENT_STORE_MAP = {
  gdrive: 'Google Drive',
  'microsoft-sharepoint': 'SharePoint',
};

export const CONTENT_STORES = {
  ...CONTENT_STORE_MAP,
  'microsoft-sharepoint': 'Microsoft SharePoint',
  internal: 'internal',
};
